var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-page", { staticClass: "flex flex-center" }, [
    _c("div", { staticClass: "column" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("q-spinner-puff", {
            staticClass: "loading-spinner loading-spinner-gold",
            staticStyle: {
              color: "#F4A724",
              display: "block",
              "margin-top": "-134px"
            },
            attrs: { size: "200px" }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }