<template>
  <q-page class="flex flex-center">
    <div class="column">
      <div class="col">
        <!-- <img class="pulse" alt="authenticating" src="/statics/icons/icon-gold.svg" height="63" style="vertical-align: middle; display: block; margin: auto;"> -->
        <q-spinner-puff size="200px" class="loading-spinner loading-spinner-gold" style="color: #F4A724; display: block; margin-top: -134px;"/>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'callback',
  props: ['auth'],
  computed: {
    init () {
      return this.$store.state.app.init
    }
  },
  watch: {
    init (newState, oldState) {
      // start the authentication when app is initialized
      if (newState) {
        this.auth.handleAuthentication()
      }
    }
  },
  data () {
    return {}
  }
}
</script>
